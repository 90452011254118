<template>
    <div>
        <!-- <div class="banner"><img src="../assets/images/banner/ptjs.png" alt=""></div> -->
        <!-- <div class="banner-wrap">            
            <div class="swiper-container" ref="banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in pingtaiList.a_lbs" :key="index" @click="goUrl(item.link)">
                        <img :src="item.pic_url" alt="">
                    </div>
                </div>
                <div class="swiper-pagination" ref="swiper-pagination"></div>
            </div>
        </div> -->
        <banner></banner>

        <div class="p-center w-1300">
            <div class="center-text">
                <p></p>
                <span>平台介绍</span>
            </div>
            <div class="jieshao" v-html="pingtaiList.content">
                河南海外仓供应链管理服务有限公司
                新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商有限公司等发起，在中国国际商会郑州商会、联钢国际、非洲商务联盟、Amanbo立体数字营销平台、海外仓集团、联合国采购中国服务中心等众多境内外企业、商协会的支持下，平台以求实求效、为公向善为服务宗旨，以一带一路、新兴国家及地区为主要市场。

                通过整合境内外各种资源，利用跨境电商、海外仓、境外展厅、当地营销团队等分销和服务渠道，以及国际营销活动、商务对接、外贸综合服务等方式，为涉外企业做好国际经贸信息与业务交流的服务，帮助企业和产品进入国际市场、提高竞争力、促进中国与世界的商贸、文化交流。
            </div>
            <div class="guimo-wrap">
                <div class="guimo-left">
                    <div class="guimo" v-for="(item,index) in pingtaiList.boxs" :key="index" >
                        <div class="guimo-text">{{item.n}}</div>
                        <div class="guimo-p">{{item.txt}}</div>
                    </div>
                    <!-- <div class="guimo">
                        <div class="guimo-text">1998</div>
                        <div class="guimo-p">新丝路外服是一个专业开展国际经贸交流与服务的平台</div>
                    </div> -->
                </div>
                <div class="guimo-right">
                    <img src="../assets/images/pingtai.jpg" alt="">
                </div>
            </div>
            <div class="center-text">
                <p></p>
                <span>发展历程</span>
            </div>
            <div class="fzlc-wrap w-1300">
                <div class="fzlc-center" v-if="timeList.length>0">                    
                    <div  class="fzlc fzlc1" v-if="timeList.length>=7">
                        <div class="fzlc-year">{{timeList[6].year}}</div>
                        <div class="fzlc-p">{{timeList[6].content}}</div>
                    </div>
                    <div  class="fzlc fzlc2" v-if="timeList.length>=6">                        
                        <div class="fzlc-p">{{timeList[5].content}}</div>
                        <div class="fzlc-year">{{timeList[5].year}}</div>
                    </div>
                    <div  class="fzlc fzlc3" v-if="timeList.length>=5">
                        <div class="fzlc-year">{{timeList[4].year}}</div>
                        <div class="fzlc-p">{{timeList[4].content}}</div>
                    </div>
                    <div  class="fzlc fzlc4" v-if="timeList.length>=4">                        
                        <div class="fzlc-p">{{timeList[3].content}}</div>
                        <div class="fzlc-year">{{timeList[3].year}}</div>
                    </div>
                    <div  class="fzlc fzlc5" v-if="timeList.length>=3">
                        <div class="fzlc-year">{{timeList[2].year}}</div>
                        <div class="fzlc-p">{{timeList[2].content}}</div>
                    </div>
                    <div  class="fzlc fzlc6"  v-if="timeList.length>=2">                        
                        <div class="fzlc-p">{{timeList[1].content}}</div>
                        <div class="fzlc-year">{{timeList[1].year}}</div>
                    </div>
                    <div  class="fzlc fzlc7" v-if="timeList.length>=1">
                        <div class="fzlc-year">{{timeList[0].year}}</div>
                        <div class="fzlc-p">{{timeList[0].content}}</div>
                    </div>
                    <img src="../assets/images/ptjsyear.png" alt="">
                </div>
            </div>
        </div>
        <div class="zhengshu">
            <div class="zhengshu-banner">
                <div class="swiper-container"  ref="zhengshubanner">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in pingtaiList.cred_urls" :key="index">
                            <!-- <div class="zhengshu-img"><img :src="baseUrl+'storage/'+item" alt=""></div> -->
                            <div class="zhengshu-img"><img :src="item" alt=""></div>
                        </div>
                        <!-- <div class="swiper-slide">
                            <div class="zhengshu-img"><img src="../assets/images/pingtai.jpg" alt=""></div>
                        </div> -->
                    </div>                
                </div>
                <div class="swiper-button-next  swiper-button-black" ref="swiper-button-next"></div>
                <div class="swiper-button-prev  swiper-button-black" ref="swiper-button-prev"></div>
            </div>
        </div>


    </div>
</template>

<script>
import banner from '../components/banner.vue';
import Swiper from '../assets/js/swiper'
import {getwes,getaxis} from '../api/index'
import CONFIG from "../utils/config.js"
    export default {
        name:"platform",
        components:{
            banner
        },
        data() {
            return {
                baseUrl:CONFIG.baseUrl,
                pingtaiList:[],
                timeList:[]          
            }
        },
        created() {
            this.getwes();
            this.getaxis()
        },
        methods: {
            goUrl(url){
                if (url) {
                    window.open(url)                     
                }else{
                    return false;
                }
            },
            getwes(){
                getwes().then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.pingtaiList=res.data;
                        this.$nextTick(()=>{
                            new Swiper(this.$refs['zhengshubanner'], {
                                slidesPerView: 4,
                                paginationClickable: true,
                                spaceBetween: 30,
                                nextButton: this.$refs['swiper-button-next'],
                                prevButton: this.$refs['swiper-button-prev'],
                            })
                        })
                        // this.$nextTick(()=>{
                        //     let mySwiper = new Swiper(this.$refs['banner'], {
                        //         // autoplay: 1000,
                        //         autoplayDisableOnInteraction : false,
                        //         // loop:true,
                        //         pagination : this.$refs['swiper-pagination'],
                        //         paginationClickable :true,
                        //     })
                        //     this.$refs['banner'].onmouseenter = function () {
                        //         mySwiper.stopAutoplay();
                        //     };
                        //     this.$refs['banner'].onmouseleave = function () {
                        //         mySwiper.startAutoplay();
                        //     }
                        // });
                        // console.log(this.pingtaiList.creds)                        
                    }
                })
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            getaxis(){
                getaxis().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.timeList = res.data;
                        
                    }
                })
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
@import "../assets/css/swiper.css" ;
    // 轮播
    .banner-wrap{width: 100%;height: 400px;}
    .banner-wrap .swiper-container{width: 100%;height: 100%;}
    .banner-wrap .swiper-slide{
        width: 100%;height: 100%;
        img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
    }
    /deep/ .banner-wrap .swiper-pagination-bullet{background: #fff;opacity: .5;}
    /deep/ .banner-wrap .swiper-pagination-bullet-active{width: 20px;border-radius: 30px;background: #fff;opacity: 1;}
    .p-center{
        margin: auto;
        .center-text{
            display: flex;align-items: center;margin-top:45px;
            p{width: 3px;height: 25px;background: #0071DB;}
            span{font-size: 20px;color: #000000;margin-left: 10px;}
        }
        .jieshao{
            margin: 45px 0;
            line-height: 2;
        }
        .guimo-wrap{
            display: flex;display: flex;align-items: center;justify-content: center;
            .guimo-left{
                width: 780px;display: flex;display: flex;flex-direction: row;flex-wrap: wrap;
                .guimo{
                    width: 160px;                    
                    margin-right: 11%;
                    margin-bottom: 80px;
                    .guimo-text{color: #363636;font-size: 50px;font-weight: bold;text-align: center;}
                    .guimo-p{color: #666666;font-size: 16px;line-height: 24px;margin-top: 10px;display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;overflow: hidden;}
                }
            }
            .guimo-right{
                width: 428px;
                img{width: 100%;}
            }
            
        }
        .fzlc-wrap{
            margin: auto;display: flex;justify-content: center;
            .fzlc-center{
                width: 564px;
                height: 708px;
                position: relative;                
                img{width: 100%;height: 100%;}
                .fzlc{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    .fzlc-year{width: 95px;height: 95px;color:#343439;font-size:28px;display: flex;align-items: center;justify-content: center;margin-right: 15px;}
                    .fzlc-p{width: 340px;color: #666666;font-size: 16px;line-height: 25px;    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;}
                }
                // .fzlc:nth-child(2n){
                //     text-align: right;
                //     .fzlc-year{margin-left: 15px;margin-right: 0;}
                // }
                .fzlc1{top: 49px;right: -355px;}
                .fzlc2{top: 133px;left: -355px;
                    text-align: right;
                    .fzlc-year{margin-left: 15px;margin-right: 0;}
                }

                .fzlc3{top: 217px;right: -355px;}
                .fzlc4{top: 301px;left: -355px;
                    text-align: right;
                    .fzlc-year{margin-left: 15px;margin-right: 0;}
                }

                .fzlc5{top: 385px;right: -355px;}
                .fzlc6{top: 469px;left: -355px;
                    text-align: right;
                    .fzlc-year{margin-left: 15px;margin-right: 0;}
                }

                .fzlc7{top: 553px;right: -355px;}
            }
        }
    }
    .zhengshu{background: #1B66BB;padding: 50px 0 30px 0;margin-top: 60px;}
    .zhengshu-banner{
        width: 1200px; height: 379px;margin:50px auto 65px auto;position: relative;
        .swiper-container{
            width: 100%;height: 100%;overflow: hidden;
            .swiper-slide{
                display: flex;align-items: center;justify-content: center;
                .zhengshu-img{width: 250px;
                    img{width: 100%;}
                }
            }
        }
        .swiper-button-prev, .swiper-button-next{width: 16px;height: 30px;background-size: 16px 30px;}
        .swiper-button-prev{left: -40px !important;}
        .swiper-button-next{right: -40px !important;}
        .swiper-button-prev.swiper-button-black{background-image: url('../assets/images/banner-left.png');}
        .swiper-button-next.swiper-button-black{background-image: url('../assets/images/banner-right.png');}
    }
    // .zhengshu-banner .swiper-slide {box-shadow: -2px 4px 18px 0px rgba(0, 113, 219, 0.1);}  

</style>